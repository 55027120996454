.user-online-badge {
  animation: unread-pulse 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  filter: drop-shadow(1px 1px 2px #53d19f);
}

.user-offline-badge {
  display: inline-block;
  transition: filter 0.3s ease-in-out;
}

@keyframes unread-pulse {
  0% {
    filter: drop-shadow(1px 1px 0px #53d19f00);
  }
  50% {
    filter: drop-shadow(1px 1px 3px #53d19fff);
  }
  100% {
    filter: drop-shadow(1px 1px 0px #53d19f00);
  }
}
