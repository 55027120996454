.send-message-input {
  border-radius: 15px;
  width: 100%;
  background-color: white;
  border: 1px solid #ababab;
  line-height: 1.2rem;
  padding: 0.25rem 0.7rem;
  padding-right: 2.2rem;
  min-height: 1.9rem;
  outline: none;
}

.send-message-input::-webkit-resizer {
  background-color: transparent;
}
.send-message-input:focus {
  box-shadow: 0 0 3px #43ad9e;
}

@keyframes pulse {
  0% {
    background-color: #f7fafc;
  }
  30% {
    background-color: #f7fafc;
  }
  50% {
    background-color: #d1ede9;
  }
  70% {
    background-color: #f7fafc;
  }
  100% {
    background-color: #f7fafc;
  }
}

.thread-preview-unread {
  animation: pulse 2500ms infinite;
  transition: background-color 0.3s;
}
.thread-preview-unread:hover {
  background-color: #d1ede9 !important;
}

.thread-preview {
  background-color: white;
  transition: background-color 0.3s;
}

.thread-preview:hover {
  background-color: #e3f4f1;
}
