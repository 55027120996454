.pulsating-shadow {
  animation: pulse-shadow 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  box-shadow: 1px 1px 5px #53d19f;
}

.not-pulsating-shadow {
  animation: none;
  box-shadow: 1px 1px 5px #00000077;
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 1px 1px 5px #53d19f;
  }
  50% {
    box-shadow: 1px 1px 10px #53d19f;
  }
  100% {
    box-shadow: 1px 1px 5px #53d19f;
  }
}
