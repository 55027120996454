/* Container for the text */
.loading-container {
  position: absolute;
  left: 0;
  top: 0;
}

.loading-span {
  position: relative;
  background-color: #f3f3f3; /* Background color of the box */
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.2;
  display: inline;
}

/* Shiny effect */
.shiny-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
