@keyframes dash {
  to {
    stroke-dashoffset: -1000;
  }
}

.animated-dashed-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.resize-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-dashed-border svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animated-dashed-border svg path {
  fill: none;
  stroke: rgb(90, 90, 90);
  stroke-dasharray: 5, 5;
  animation: dash 30s linear infinite;
}

.resize-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -6px;
  pointer-events: auto;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  /* border: 1px solid #aaa; */
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);

  > img {
    width: 14px;
    height: 14px;
    opacity: 0.4;
    pointer-events: none;
  }
}

.dragging {
  cursor: grabbing;
}

span.field-name-tooltip {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #618b97;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 100;
}

.move-handle {
}
